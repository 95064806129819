import React, { Component, KeyboardEvent } from 'react';
import { Howl } from 'howler';
import chroma from 'chroma-js';
import { Shape } from './Shape';


interface ShapeInterface {
  color:string;
  position: {
    height:number;
    width:number;
  },
  size:number;
}
interface HomeState {
  shapes:ShapeInterface[];
  currentColor?:any;
}
export class Home extends Component {
  state:HomeState = {
    shapes: [],
    currentColor: 'hotpink'
  }

  createShape() {
    const height = Math.floor(Math.random() * window.innerHeight) + 1;
    const width = Math.floor(Math.random() * window.innerWidth) + 1;
    const newShape:ShapeInterface = {
      position: { height, width },
      color: 'hotpink',
      size: 1
    }
    this.setState({ shapes: this.state.shapes.push(newShape)});
  }

  destroyShape() {

  }

  _handleKeyDown(event:any) {
    this.createShape();
    // var sound = new Howl({
    //   src: alphabet[event.key]
    // });
    // sound.play();
  }

  componentDidMount() {
    document.addEventListener("keydown", (e) => this._handleKeyDown(e));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", (e) => this._handleKeyDown(e));
  }

  render() {
    return (
      <Shape />
    );
  }
}
