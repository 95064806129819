import React, { Component } from 'react';
import color from 'sc-color';
import styled from 'styled-components';

const ShapeContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: ${props => props.color};
`;


interface ShapeState {
  color:string;
  position: {
    height:number;
    width:number;
  },
  size:number;
}
export class Shape extends Component {
  state:ShapeState = {
    color: 'hotpink',
    size: 1,
    position: {
      height: Math.floor(Math.random() * window.innerHeight) + 1,
      width: Math.floor(Math.random() * window.innerWidth) + 1,
    },
  }

  render() {
    const c1 = color('#0FF').hue('-100').hex6();
    const c2 = color('#0FF').hue('-1').hex6();
    const c3 = color('#0FF').hue('-40').hex6();
    const c4 = color('#0FF').hue('-10').hex6();
    return (
      <>
        <ShapeContainer color={c1}/>
        <ShapeContainer color={c2}/>
        <ShapeContainer color={c3}/>
        <ShapeContainer color={c4}/>
      </>
    );
  }
}
